
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexL9YQaPEkuaqLVNE1TU5_45G_6Anj_7G2q_45EuEAhPBVP7gMeta } from "/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/pages/index.vue?macro=true";
import { default as indexgnzXmNoQ5X3yhSbjIbJqRxL_45Xxe4sqD5T8XXmuqFAvoMeta } from "/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/pages/[[lang]]/index.vue?macro=true";
import { default as startZCR4jMeaD5gKv1td_fjeDsz8o__4IMnHRuv9weHV1ooMeta } from "/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/pages/[[lang]]/voucher/start.vue?macro=true";
import { default as start_45I4Ay_bmZA_45BK3dFaHd0VQotzUeRvyOMcUpzYui7DwMMeta } from "/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/pages/[[lang]]/purchase/start.vue?macro=true";
import { default as checkoutntsPc49eSx6lfoxNfQAEEad5kKF_45W4qlJmcNo6L7AeYMeta } from "/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/pages/[[lang]]/service-booking/checkout.vue?macro=true";
import { default as koMcM4i13Nd7ois26E_jC9pm40f8tz9SAae4d0LQNhGTMMeta } from "/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue?macro=true";
import { default as confirmationVHrrCG_8fPHwoprgdBYknEyGy516ti8IWx0sxZiSEn4Meta } from "/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue?macro=true";
import { default as confirmationARwU3QB3xpvK5xxkkUnTebNwOUuQoKafL60pI4nh04EMeta } from "/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue?macro=true";
import { default as buyeruWFdzhJEpsG6osZTcAbetUlKUjEGJWaEP4d98fOF15cMeta } from "/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as buyerHP8k3VX7vx4kY0HcQ6yU_PTMkDxmw1nF_45zmA3sgQtyUMeta } from "/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as paymentIqX1KFcU2Qh36Mg4NeTy6ztynFgzvJv5v_tEUKr_45MX4Meta } from "/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue?macro=true";
import { default as challenge_HEU9KZz4mn_45vfEwzb3w6Fl5rF7VGNRMI_tzqiso1NkMeta } from "/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue?macro=true";
import { default as paymentWmsjYhy_45bs95Q2x_45_q4ErEGhKuFgOnUq0cmSJ5JbdmkMeta } from "/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue?macro=true";
import { default as SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta } from "/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaList.page.vue?macro=true";
import { default as SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta } from "/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue?macro=true";
import { default as ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta } from "/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue?macro=true";
export default [
  {
    name: indexL9YQaPEkuaqLVNE1TU5_45G_6Anj_7G2q_45EuEAhPBVP7gMeta?.name ?? "index",
    path: indexL9YQaPEkuaqLVNE1TU5_45G_6Anj_7G2q_45EuEAhPBVP7gMeta?.path ?? "/",
    props: indexL9YQaPEkuaqLVNE1TU5_45G_6Anj_7G2q_45EuEAhPBVP7gMeta?.props ?? false,
    meta: indexL9YQaPEkuaqLVNE1TU5_45G_6Anj_7G2q_45EuEAhPBVP7gMeta || {},
    alias: indexL9YQaPEkuaqLVNE1TU5_45G_6Anj_7G2q_45EuEAhPBVP7gMeta?.alias || [],
    redirect: indexL9YQaPEkuaqLVNE1TU5_45G_6Anj_7G2q_45EuEAhPBVP7gMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/pages/index.vue")
  },
  {
    name: indexgnzXmNoQ5X3yhSbjIbJqRxL_45Xxe4sqD5T8XXmuqFAvoMeta?.name ?? "lang",
    path: indexgnzXmNoQ5X3yhSbjIbJqRxL_45Xxe4sqD5T8XXmuqFAvoMeta?.path ?? "/:lang?",
    props: indexgnzXmNoQ5X3yhSbjIbJqRxL_45Xxe4sqD5T8XXmuqFAvoMeta?.props ?? false,
    meta: indexgnzXmNoQ5X3yhSbjIbJqRxL_45Xxe4sqD5T8XXmuqFAvoMeta || {},
    alias: indexgnzXmNoQ5X3yhSbjIbJqRxL_45Xxe4sqD5T8XXmuqFAvoMeta?.alias || [],
    redirect: indexgnzXmNoQ5X3yhSbjIbJqRxL_45Xxe4sqD5T8XXmuqFAvoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/pages/[[lang]]/index.vue")
  },
  {
    name: startZCR4jMeaD5gKv1td_fjeDsz8o__4IMnHRuv9weHV1ooMeta?.name ?? "lang-voucher-start",
    path: startZCR4jMeaD5gKv1td_fjeDsz8o__4IMnHRuv9weHV1ooMeta?.path ?? "/:lang?/voucher/start",
    props: startZCR4jMeaD5gKv1td_fjeDsz8o__4IMnHRuv9weHV1ooMeta?.props ?? false,
    meta: startZCR4jMeaD5gKv1td_fjeDsz8o__4IMnHRuv9weHV1ooMeta || {},
    alias: startZCR4jMeaD5gKv1td_fjeDsz8o__4IMnHRuv9weHV1ooMeta?.alias || [],
    redirect: startZCR4jMeaD5gKv1td_fjeDsz8o__4IMnHRuv9weHV1ooMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/pages/[[lang]]/voucher/start.vue")
  },
  {
    name: start_45I4Ay_bmZA_45BK3dFaHd0VQotzUeRvyOMcUpzYui7DwMMeta?.name ?? "lang-purchase-start",
    path: start_45I4Ay_bmZA_45BK3dFaHd0VQotzUeRvyOMcUpzYui7DwMMeta?.path ?? "/:lang?/purchase/start",
    props: start_45I4Ay_bmZA_45BK3dFaHd0VQotzUeRvyOMcUpzYui7DwMMeta?.props ?? false,
    meta: start_45I4Ay_bmZA_45BK3dFaHd0VQotzUeRvyOMcUpzYui7DwMMeta || {},
    alias: start_45I4Ay_bmZA_45BK3dFaHd0VQotzUeRvyOMcUpzYui7DwMMeta?.alias || [],
    redirect: start_45I4Ay_bmZA_45BK3dFaHd0VQotzUeRvyOMcUpzYui7DwMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/pages/[[lang]]/purchase/start.vue")
  },
  {
    name: checkoutntsPc49eSx6lfoxNfQAEEad5kKF_45W4qlJmcNo6L7AeYMeta?.name ?? "lang-service-booking-checkout",
    path: checkoutntsPc49eSx6lfoxNfQAEEad5kKF_45W4qlJmcNo6L7AeYMeta?.path ?? "/:lang?/service-booking/checkout",
    props: checkoutntsPc49eSx6lfoxNfQAEEad5kKF_45W4qlJmcNo6L7AeYMeta?.props ?? false,
    meta: checkoutntsPc49eSx6lfoxNfQAEEad5kKF_45W4qlJmcNo6L7AeYMeta || {},
    alias: checkoutntsPc49eSx6lfoxNfQAEEad5kKF_45W4qlJmcNo6L7AeYMeta?.alias || [],
    redirect: checkoutntsPc49eSx6lfoxNfQAEEad5kKF_45W4qlJmcNo6L7AeYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/pages/[[lang]]/service-booking/checkout.vue")
  },
  {
    name: koMcM4i13Nd7ois26E_jC9pm40f8tz9SAae4d0LQNhGTMMeta?.name ?? "lang-purchase-packagepurchaseuuid-ko",
    path: koMcM4i13Nd7ois26E_jC9pm40f8tz9SAae4d0LQNhGTMMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/ko",
    props: koMcM4i13Nd7ois26E_jC9pm40f8tz9SAae4d0LQNhGTMMeta?.props ?? false,
    meta: koMcM4i13Nd7ois26E_jC9pm40f8tz9SAae4d0LQNhGTMMeta || {},
    alias: koMcM4i13Nd7ois26E_jC9pm40f8tz9SAae4d0LQNhGTMMeta?.alias || [],
    redirect: koMcM4i13Nd7ois26E_jC9pm40f8tz9SAae4d0LQNhGTMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue")
  },
  {
    name: confirmationVHrrCG_8fPHwoprgdBYknEyGy516ti8IWx0sxZiSEn4Meta?.name ?? "lang-voucher-voucherpurchaseuuid-confirmation",
    path: confirmationVHrrCG_8fPHwoprgdBYknEyGy516ti8IWx0sxZiSEn4Meta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/confirmation",
    props: confirmationVHrrCG_8fPHwoprgdBYknEyGy516ti8IWx0sxZiSEn4Meta?.props ?? false,
    meta: confirmationVHrrCG_8fPHwoprgdBYknEyGy516ti8IWx0sxZiSEn4Meta || {},
    alias: confirmationVHrrCG_8fPHwoprgdBYknEyGy516ti8IWx0sxZiSEn4Meta?.alias || [],
    redirect: confirmationVHrrCG_8fPHwoprgdBYknEyGy516ti8IWx0sxZiSEn4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue")
  },
  {
    name: confirmationARwU3QB3xpvK5xxkkUnTebNwOUuQoKafL60pI4nh04EMeta?.name ?? "lang-purchase-packagepurchaseuuid-confirmation",
    path: confirmationARwU3QB3xpvK5xxkkUnTebNwOUuQoKafL60pI4nh04EMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/confirmation",
    props: confirmationARwU3QB3xpvK5xxkkUnTebNwOUuQoKafL60pI4nh04EMeta?.props ?? false,
    meta: confirmationARwU3QB3xpvK5xxkkUnTebNwOUuQoKafL60pI4nh04EMeta || {},
    alias: confirmationARwU3QB3xpvK5xxkkUnTebNwOUuQoKafL60pI4nh04EMeta?.alias || [],
    redirect: confirmationARwU3QB3xpvK5xxkkUnTebNwOUuQoKafL60pI4nh04EMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue")
  },
  {
    name: buyeruWFdzhJEpsG6osZTcAbetUlKUjEGJWaEP4d98fOF15cMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-buyer",
    path: buyeruWFdzhJEpsG6osZTcAbetUlKUjEGJWaEP4d98fOF15cMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/buyer",
    props: buyeruWFdzhJEpsG6osZTcAbetUlKUjEGJWaEP4d98fOF15cMeta?.props ?? false,
    meta: buyeruWFdzhJEpsG6osZTcAbetUlKUjEGJWaEP4d98fOF15cMeta || {},
    alias: buyeruWFdzhJEpsG6osZTcAbetUlKUjEGJWaEP4d98fOF15cMeta?.alias || [],
    redirect: buyeruWFdzhJEpsG6osZTcAbetUlKUjEGJWaEP4d98fOF15cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: buyerHP8k3VX7vx4kY0HcQ6yU_PTMkDxmw1nF_45zmA3sgQtyUMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-buyer",
    path: buyerHP8k3VX7vx4kY0HcQ6yU_PTMkDxmw1nF_45zmA3sgQtyUMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/buyer",
    props: buyerHP8k3VX7vx4kY0HcQ6yU_PTMkDxmw1nF_45zmA3sgQtyUMeta?.props ?? false,
    meta: buyerHP8k3VX7vx4kY0HcQ6yU_PTMkDxmw1nF_45zmA3sgQtyUMeta || {},
    alias: buyerHP8k3VX7vx4kY0HcQ6yU_PTMkDxmw1nF_45zmA3sgQtyUMeta?.alias || [],
    redirect: buyerHP8k3VX7vx4kY0HcQ6yU_PTMkDxmw1nF_45zmA3sgQtyUMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: paymentIqX1KFcU2Qh36Mg4NeTy6ztynFgzvJv5v_tEUKr_45MX4Meta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-payment",
    path: paymentIqX1KFcU2Qh36Mg4NeTy6ztynFgzvJv5v_tEUKr_45MX4Meta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/payment",
    props: paymentIqX1KFcU2Qh36Mg4NeTy6ztynFgzvJv5v_tEUKr_45MX4Meta?.props ?? false,
    meta: paymentIqX1KFcU2Qh36Mg4NeTy6ztynFgzvJv5v_tEUKr_45MX4Meta || {},
    alias: paymentIqX1KFcU2Qh36Mg4NeTy6ztynFgzvJv5v_tEUKr_45MX4Meta?.alias || [],
    redirect: paymentIqX1KFcU2Qh36Mg4NeTy6ztynFgzvJv5v_tEUKr_45MX4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue")
  },
  {
    name: challenge_HEU9KZz4mn_45vfEwzb3w6Fl5rF7VGNRMI_tzqiso1NkMeta?.name ?? "lang-voucher-voucherpurchaseuuid-redsys-challenge",
    path: challenge_HEU9KZz4mn_45vfEwzb3w6Fl5rF7VGNRMI_tzqiso1NkMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/redsys/challenge",
    props: challenge_HEU9KZz4mn_45vfEwzb3w6Fl5rF7VGNRMI_tzqiso1NkMeta?.props ?? false,
    meta: challenge_HEU9KZz4mn_45vfEwzb3w6Fl5rF7VGNRMI_tzqiso1NkMeta || {},
    alias: challenge_HEU9KZz4mn_45vfEwzb3w6Fl5rF7VGNRMI_tzqiso1NkMeta?.alias || [],
    redirect: challenge_HEU9KZz4mn_45vfEwzb3w6Fl5rF7VGNRMI_tzqiso1NkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue")
  },
  {
    name: paymentWmsjYhy_45bs95Q2x_45_q4ErEGhKuFgOnUq0cmSJ5JbdmkMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-payment",
    path: paymentWmsjYhy_45bs95Q2x_45_q4ErEGhKuFgOnUq0cmSJ5JbdmkMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/payment",
    props: paymentWmsjYhy_45bs95Q2x_45_q4ErEGhKuFgOnUq0cmSJ5JbdmkMeta?.props ?? false,
    meta: paymentWmsjYhy_45bs95Q2x_45_q4ErEGhKuFgOnUq0cmSJ5JbdmkMeta || {},
    alias: paymentWmsjYhy_45bs95Q2x_45_q4ErEGhKuFgOnUq0cmSJ5JbdmkMeta?.alias || [],
    redirect: paymentWmsjYhy_45bs95Q2x_45_q4ErEGhKuFgOnUq0cmSJ5JbdmkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue")
  },
  {
    name: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.name ?? "Spa by location landing: 0",
    path: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.path ?? "/spa-balneario/",
    props: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.props ?? false,
    meta: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta || {},
    alias: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.alias || [],
    redirect: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.name ?? "Spa by location landing: 1",
    path: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.path ?? "/spa-balneario/cadiz/",
    props: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.props ?? false,
    meta: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta || {},
    alias: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.alias || [],
    redirect: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.name ?? "Spa by location landing: 2",
    path: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.path ?? "/spa-balneario/cadiz/barbate/",
    props: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.props ?? false,
    meta: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta || {},
    alias: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.alias || [],
    redirect: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.name ?? "Spa by location landing: 3",
    path: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.path ?? "/spa-balneario/cadiz/cadiz/",
    props: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.props ?? false,
    meta: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta || {},
    alias: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.alias || [],
    redirect: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.name ?? "Spa by location landing: 4",
    path: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.path ?? "/spa-balneario/cadiz/chiclana-de-la-frontera/",
    props: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.props ?? false,
    meta: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta || {},
    alias: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.alias || [],
    redirect: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.name ?? "Spa by location landing: 5",
    path: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.path ?? "/spa-balneario/cadiz/grazalema/",
    props: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.props ?? false,
    meta: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta || {},
    alias: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.alias || [],
    redirect: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.name ?? "Spa by location landing: 6",
    path: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.path ?? "/spa-balneario/cadiz/novo-sancti-petri/",
    props: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.props ?? false,
    meta: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta || {},
    alias: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.alias || [],
    redirect: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.name ?? "Spa by location landing: 7",
    path: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.path ?? "/spa-balneario/cadiz/rota/",
    props: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.props ?? false,
    meta: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta || {},
    alias: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.alias || [],
    redirect: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.name ?? "Spa by location landing: 8",
    path: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.path ?? "/spa-balneario/cadiz/tarifa/",
    props: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.props ?? false,
    meta: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta || {},
    alias: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.alias || [],
    redirect: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.name ?? "Spa by location landing: 9",
    path: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.path ?? "/spa-balneario/cadiz/vejer-de-la-frontera/",
    props: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.props ?? false,
    meta: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta || {},
    alias: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.alias || [],
    redirect: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.name ?? "Spa by location landing: 10",
    path: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.path ?? "/spa-balneario/cadiz/zahara/",
    props: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.props ?? false,
    meta: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta || {},
    alias: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.alias || [],
    redirect: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.name ?? "Spa by location landing: 11",
    path: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.path ?? "/spa-balneario/guadalajara/",
    props: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.props ?? false,
    meta: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta || {},
    alias: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.alias || [],
    redirect: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.name ?? "Spa by location landing: 12",
    path: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.path ?? "/spa-balneario/guadalajara/abanades/",
    props: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.props ?? false,
    meta: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta || {},
    alias: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.alias || [],
    redirect: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.name ?? "Spa by location landing: 13",
    path: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.path ?? "/spa-balneario/guadalajara/brihuega/",
    props: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.props ?? false,
    meta: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta || {},
    alias: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.alias || [],
    redirect: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.name ?? "Spa by location landing: 14",
    path: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.path ?? "/spa-balneario/mallorca/",
    props: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.props ?? false,
    meta: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta || {},
    alias: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.alias || [],
    redirect: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.name ?? "Spa by location landing: 15",
    path: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.path ?? "/spa-balneario/mallorca/cala-millor/",
    props: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.props ?? false,
    meta: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta || {},
    alias: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.alias || [],
    redirect: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.name ?? "Spa by location landing: 16",
    path: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.path ?? "/spa-balneario/mallorca/calvia/",
    props: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.props ?? false,
    meta: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta || {},
    alias: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.alias || [],
    redirect: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.name ?? "Spa by location landing: 17",
    path: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.path ?? "/spa-balneario/mallorca/colonia-sant-jordi/",
    props: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.props ?? false,
    meta: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta || {},
    alias: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.alias || [],
    redirect: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.name ?? "Spa by location landing: 18",
    path: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.path ?? "/spa-balneario/mallorca/felanitx/",
    props: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.props ?? false,
    meta: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta || {},
    alias: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.alias || [],
    redirect: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.name ?? "Spa by location landing: 19",
    path: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.path ?? "/spa-balneario/mallorca/llucmajor/",
    props: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.props ?? false,
    meta: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta || {},
    alias: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.alias || [],
    redirect: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.name ?? "Spa by location landing: 20",
    path: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.path ?? "/spa-balneario/mallorca/montuiri/",
    props: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.props ?? false,
    meta: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta || {},
    alias: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.alias || [],
    redirect: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.name ?? "Spa by location landing: 21",
    path: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.path ?? "/spa-balneario/mallorca/muro/",
    props: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.props ?? false,
    meta: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta || {},
    alias: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.alias || [],
    redirect: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.name ?? "Spa by location landing: 22",
    path: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.path ?? "/spa-balneario/mallorca/palma/",
    props: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.props ?? false,
    meta: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta || {},
    alias: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.alias || [],
    redirect: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.name ?? "Spa by location landing: 23",
    path: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.path ?? "/spa-balneario/mallorca/pollenca/",
    props: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.props ?? false,
    meta: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta || {},
    alias: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.alias || [],
    redirect: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.name ?? "Spa by location landing: 24",
    path: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.path ?? "/spa-balneario/mallorca/sant-llorenc-des-cardassar/",
    props: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.props ?? false,
    meta: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta || {},
    alias: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.alias || [],
    redirect: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.name ?? "Spa by location landing: 25",
    path: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.path ?? "/spa-balneario/mallorca/soller/",
    props: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.props ?? false,
    meta: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta || {},
    alias: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.alias || [],
    redirect: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.name ?? "Spa by location landing: 26",
    path: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.path ?? "/spa-balneario/mallorca/son-servera/",
    props: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.props ?? false,
    meta: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta || {},
    alias: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.alias || [],
    redirect: SpaList_46pageNoiPRYpcDf1OLMBEzFVO1KgdpGuilZhRoUr1kMn7XmcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 0",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/cadiz/spa-cortijo/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 1",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 2",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/mallorca/hm-ayron-park/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 3",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/mallorca/hm-palma-blanc/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 4",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 5",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/cadiz/spa-la-residencia-tarifa/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 6",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 7",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience-hipotels-hipocampo-playa/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 8",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/mallorca/coronado-thalasso-spa/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 9",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-waves-playa-de-muro/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 10",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-bahia-de-palma/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 11",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 12",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 13",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 14",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/mallorca/silentia-spa-wellness/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 15",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/guadalajara/spa-castilla-termal-brihuega/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 16",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 17",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 18",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 19",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 20",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 21",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/mallorca/pure-spa-by-natura-bisse/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 22",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/mallorca/spa-hipotels-eurotel-punta-rotja/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 23",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/mallorca/hm-jaime-iii/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 24",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 25",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/cadiz/sens-spa/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 26",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 27",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 28",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/mallorca/spa-bellesa-claret/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 29",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 30",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience-hipotels-flamenco/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 31",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/guadalajara/spa-rural-los-anades/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 32",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/mallorca/spa-es-port/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 33",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 34",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 35",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 36",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 37",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 38",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/cadiz/spa-tarifa/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 39",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/cadiz/hammam-vejer/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 40",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/mallorca/aumallia-hotel-spa/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.name ?? "Spa landing: 41",
    path: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/",
    props: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.props ?? false,
    meta: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta || {},
    alias: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.alias || [],
    redirect: SpaDetail_46pagez217XMCfnuSbU9ugrHg2ZCX944kfHdz8AhCGUnBRCmMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 0",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-cortijo/circuito-estancia-1-noche-para-2/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 1",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-cortijo/momento-spa-masaje-para-2/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 2",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-cortijo/spa-masaje-para-2/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 3",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-cortijo/circuito-merienda-para-2/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 4",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-cortijo/circuito-cena-para-2/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 5",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-cortijo/circuito-spa-para-2/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 6",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/circuito-spa-coolifting/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 7",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/facial-antiage-con-nanotecnologia/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 8",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/algoterapia/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 9",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/ritual-ca-nostra/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 10",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/ritual-primavera-aromas-de-la-pradera/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 11",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/acupuntura/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 12",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/fallin-relax/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 13",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/peeling-personalizado/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 14",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/brunch-circuito-de-spa/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 15",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/circuito-spa/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 16",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/lodoterapia-espalda/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 17",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/peeling-masaje/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 18",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/bye-dolor/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 19",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/ritual-chocolate-y-rosas-nutri/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 20",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/masaje-relajante/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 21",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/masaje-antiestres-circuito-de-spa/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 22",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/desayuno-buffet-circuito-de-spa/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 23",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/masaje-espalda-circuito-de-spa/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 24",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/almuerzo-circuito-de-spa/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 25",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/hm-ayron-park/day-pass-de-tarde-spa-cena-buffet/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 26",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/hm-ayron-park/day-pass-con-desayuno-buffet-y-acceso-spa/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 27",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/hm-ayron-park/sky-bar-cena-en-el-restaurante-tamashi-spa-cocktail/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 28",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/hm-ayron-park/day-pass-con-cama-balinesa-almuerzo-a-la-carta-para-2-personas/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 29",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/hm-ayron-park/day-pass-con-brunch-y-acceso-spa/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 30",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/hm-palma-blanc/circuito-spa-masaje-25-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 31",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/hm-palma-blanc/circuito-spa-masaje-50-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 32",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/hm-palma-blanc/circuito-spa-desayuno-buffet/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 33",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/hm-palma-blanc/circuito-spa/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 34",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/hm-palma-blanc/circuito-spa-masaje-aromatico/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 35",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/hm-palma-blanc/daypass-circuito-spa-masaje-de-25-menu-migdia-en-restaurante-aromata-de-andreu-genestra/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 36",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/hm-palma-blanc/daypass-circuito-spa-masaje-de-25-menu-degustacion-aromata-en-restaurante-aromata-de-andreu-genestra/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 37",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/hm-palma-blanc/daypass-circuito-spa-menu-migdia-en-restaurante-aromata-de-andreu-genestra/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 38",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/hm-palma-blanc/daypass-circuito-spa-menu-degustacion-aromata-en-restaurante-aromata-de-andreu-genestra/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 39",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/lomi-lomi-nui-70-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 40",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/foot-reflexology/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 41",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/deep-tissue/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 42",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/ayurveda-royal-thai-poultice/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 43",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/antiestres/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 44",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/oriental-55-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 45",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/earth-experience/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 46",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/andalucia-playa-signature-ritual/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 47",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/body-sculpt-anti-cell/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 48",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/spa-breakfast/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 49",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/circuito-hydrowellness/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 50",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/circuito-spa-privado/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 51",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/spa-dinner-market-experience/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 52",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/stone-therapy/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 53",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-la-residencia-tarifa/like-a-diamond/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 54",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-la-residencia-tarifa/endeless-summer/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 55",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-la-residencia-tarifa/circuito-spa-para-2/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 56",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-la-residencia-tarifa/beach-rehab/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 57",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-la-residencia-tarifa/southside-story/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 58",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-la-residencia-tarifa/fall-in-love/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 59",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-la-residencia-tarifa/la-residencia-crazy-in-love/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 60",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-la-residencia-tarifa/time-for-you/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 61",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience/circuito-spa-masaje-50-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 62",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience/circuito-spa-masaje-holistico-jacuzzi-privado-para-2-personas/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 63",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience/circuito-spa-masaje-sensorial/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 64",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience/circuito-spa-masaje-30-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 65",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience/circuito-spa-masaje-30-min-para-2-personas/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 66",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience/circuito-spa-masaje-50-min-para-2-personas/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 67",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience/circuito-spa-masaje-30-min-desayuno-buffet/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 68",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience/circuito-spa-masaje-holistico-desayuno-buffet-para-2-personas/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 69",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience/circuito-spa-masaje-holistico-cena-de-gala-para-2-personas/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 70",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience/circuito-spa-tratamiento-de-andulacion/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 71",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience-hipotels-hipocampo-playa/pack-have-lunch/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 72",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience-hipotels-hipocampo-playa/zona-de-aguas-masaje-50-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 73",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience-hipotels-hipocampo-playa/pack-relax-matutino/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 74",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience-hipotels-hipocampo-playa/zona-de-aguas-masaje-25-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 75",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience-hipotels-hipocampo-playa/zona-de-aguas-masaje-sensorial/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 76",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience-hipotels-hipocampo-playa/pack-romantico/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 77",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/coronado-thalasso-spa/spa-desayuno/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 78",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/coronado-thalasso-spa/day-pass-spa/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 79",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/coronado-thalasso-spa/spa/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 80",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-waves-playa-de-muro/masaje-lomi-lomi-nui-circuito-de-aguas/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 81",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-waves-playa-de-muro/gastrospa/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 82",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-waves-playa-de-muro/circuito-de-aguas-tratamiento-facial-detox-botox-like-spiruline-boost/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 83",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-waves-playa-de-muro/masaje-antiestres-aromatico-circuito-de-aguas/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 84",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-waves-playa-de-muro/degustacion-de-vinos-y-quesos-circuito-de-aguas/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 85",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-waves-playa-de-muro/beauty-party/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 86",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-waves-playa-de-muro/mananas-deluxe/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 87",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-waves-playa-de-muro/senses-night/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 88",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-waves-playa-de-muro/especial-cumpleanos/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 89",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-waves-playa-de-muro/circuito-de-aguas/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 90",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-bahia-de-palma/masaje-antiestres/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 91",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-bahia-de-palma/bahia-signature-ritual-especial-parejas/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 92",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-bahia-de-palma/facial-express-marine-anti-fatigue/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 93",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-bahia-de-palma/masaje-deep-tissue/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 94",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-bahia-de-palma/hidraforce-source-marine/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 95",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-bahia-de-palma/circuito-termal-individual-1/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 96",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/dia-especial-de-spa-en-pareja-masaje-25-min-spa/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 97",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/dia-especial-de-spa-en-pareja-masaje-50-min-spa/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 98",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/masaje-aloe-vera/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 99",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/masaje-en-pareja-25/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 100",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/masaje-descontracturante-relajante-25/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 101",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/masaje-en-parejas-50/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 102",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/circuito-spa-masaje-relajante-cena-buffet/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 103",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/circuito-spa-masaje-relajante-cena-buffet-en-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 104",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/dia-especial-de-spa-en-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 105",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/circuito-de-spa/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 106",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/desayuno-buffet-circuito-spa/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 107",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/desayuno-buffet-circuito-spa-masaje-1/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 108",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/circuito-spa-cena-buffet/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 109",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/masaje-chocolate-juvenil/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 110",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/masaje-con-piedras-volcanicas/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 111",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/masaje-relajante-50-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 112",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/masaje-relajante-20-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 113",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/viaje-al-eden-tropical/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 114",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/ritual-brisa-de-ganesha/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 115",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/viaje-a-la-provenza/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 116",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/masaje-placido-sueno/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 117",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/viaje-a-la-ruta-de-las-delicias/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 118",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/apacible-paraiso/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 119",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/masaje-deep-tissue/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 120",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/ritual-hydraluronic/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 121",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/circuito-spa-menores-4-a-17-99-anos/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 122",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/circuito-spa-adulto/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 123",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/experiencia-sun-repair/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 124",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/silentia-spa-wellness/especial-residentes-spa-masaje/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 125",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/silentia-spa-wellness/masaje-con-pindas-y-esferas-calientes-de-sal-assal-40-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 126",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/silentia-spa-wellness/masaje-celebracion-de-tu-cumpleanos/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 127",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/silentia-spa-wellness/ruta-de-las-delicias/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 128",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/silentia-spa-wellness/masaje-con-aceite-de-cristal-by-silentia-50-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 129",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/silentia-spa-wellness/masaje-balines-50-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 130",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/silentia-spa-wellness/acceso-silentia-spa-residentes/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 131",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/silentia-spa-wellness/escapada-placer-de-vivir-sin-prisa/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 132",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/silentia-spa-wellness/masaje-pre-mama-40-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 133",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/silentia-spa-wellness/masaje-celebracion-de-tu-aniversario/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 134",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/silentia-spa-wellness/masaje-energizante-ayurvedico-de-la-india-60-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 135",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/silentia-spa-wellness/masaje-con-aceite-de-cristal-by-silentia-25-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 136",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/silentia-spa-wellness/banos/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 137",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/guadalajara/spa-castilla-termal-brihuega/envolturas/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 138",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/guadalajara/spa-castilla-termal-brihuega/bano-jamaica-1/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 139",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/guadalajara/spa-castilla-termal-brihuega/masaje-terapeutico-personalizado-55-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 140",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/guadalajara/spa-castilla-termal-brihuega/quiromasaje/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 141",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/guadalajara/spa-castilla-termal-brihuega/masaje-ayurveda/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 142",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/guadalajara/spa-castilla-termal-brihuega/masaje-1750/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 143",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/guadalajara/spa-castilla-termal-brihuega/masaje-relajante-con-aromaterapia/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 144",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/guadalajara/spa-castilla-termal-brihuega/piscina-termal-adultos-externos/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 145",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/guadalajara/spa-castilla-termal-brihuega/piscina-termal-entrada-ninos-externos-0-a-12-anos/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 146",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/guadalajara/spa-castilla-termal-brihuega/masaje-de-piedras-volcanicas-1/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 147",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/amigas-para-siempre/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 148",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/masaje-espalda-aromaterapia/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 149",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/instante-romantico/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 150",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/energy-time/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 151",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/collagen-booster/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 152",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/aqua-rituals/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 153",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/abhyanga-viaje-sensorial/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 154",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/ritual-mallorca-wellness/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 155",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/ritual-ayurvedico-armonia-total/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 156",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/masaje-relax-espalda/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 157",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/masaje-ritual-en-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 158",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/masaje-cuerpo-entero-y-craneofacial/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 159",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/ritual-piedras-calientes/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 160",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/deep-tissue/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 161",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/masaje-cuerpo-entero/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 162",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/back-detox/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 163",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/serenity/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 164",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/hidraforce-source-marine/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 165",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/for-men-ocean-intense/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 166",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/antiestres/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 167",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/circuito-termal-doble/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 168",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/desayuno-circuito-termal/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 169",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/circuito-termal-individual/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 170",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/circuito-hydrowellness/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 171",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/brunch-circuito-termal/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 172",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/deep-tissue-40-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 173",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/stone-therapy-1/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 174",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/especifico-localizado/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 175",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/after-work-masaje-25min-circuito-termal/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 176",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/masaje-aromatico-45min-circuito-termal/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 177",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/masaje-lomi-lomi-nui-55min-circuito-termal/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 178",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/masaje-relajante-50-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 179",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/viaje-al-eden-tropical/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 180",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/viaje-a-la-ruta-de-las-delicias/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 181",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/viaje-a-la-provenza/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 182",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/ritual-bienestar-circuito/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 183",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/masaje-relajante-20-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 184",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/ritual-radiance-c/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 185",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/viaje-a-la-provenza-circuito/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 186",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/ritual-hydraluronic/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 187",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/ritual-srns/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 188",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/exfoliantes-corporales/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 189",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/circuito-spa-adulto/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 190",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/circuito-spa-menores-de-4-a-17-anos/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 191",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/envolturas-corporales/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 192",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/masaje-sunset-en-egipto/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 193",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/masaje-de-piedras-calientes/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 194",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/masaje-piedras-volcanicas/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 195",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/2-desayunos-piscina-cubierta-bano-turco-sauna-masaje-en-pareja-25-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 196",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/dia-especial-en-pareja-masaje-50-min-piscina-cubierta-sauna-bano-turco/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 197",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/masaje-relajante-45/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 198",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/masaje-relajante-en-pareja-50/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 199",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/cena-buffet-piscina-cubierta-sauna-bano-turco-masaje-de-45min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 200",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/masaje-relajante-25/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 201",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/cena-buffet-masaje-en-pareja-de-25min-piscina-cubierta-sauna-bano-turco/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 202",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/2-desayunos-piscina-climatizada-sauna-bano-turco-masaje-50-minutos-en-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 203",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/cena-buffet-masaje-50-min-en-pareja-piscina-cubierta-sauna-bano-turco/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 204",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/desayuno-masaje-45-min-piscina-cubierta-sauna-bano-turco/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 205",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/masaje-hawaiano/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 206",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/masaje-relajante-en-pareja-25/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 207",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/dia-especial-en-pareja-masaje-25-min-piscina-cubierta-sauna-bano-turco/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 208",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/desayuno-masaje-25-min-piscina-cubierta-sauna-bano-turco/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 209",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/cena-buffet-piscina-cubierta-sauna-bano-turco-masaje-de-25min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 210",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/hidratacion-facial/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 211",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/piscina-cubierta/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 212",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/masaje-juvenil-aromatico/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 213",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/diamond-well-living-by-natura-bisse-90/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 214",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/ritual-mediterranean-by-natura-bisse/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 215",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/ritual-de-piedras-de-sal-by-comfort-zone/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 216",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/the-cure-therapy-by-natura-bisse/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 217",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/ritual-finca-serena-by-finca-serena/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 218",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/masaje-bespoke-60/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 219",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/diamond-well-living-by-natura-bisse-60/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 220",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/podal-reflexology/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 221",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/masaje-muscular-60/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 222",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/diamond-experience-facial-ritual-by-natura-bisse/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 223",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/masaje-relajante-60/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 224",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/citrus-vita-essence-by-natura-bisse/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 225",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/shaping-massage-by-comfort-zone/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 226",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/sublime-skin-by-comfort-zone/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 227",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/remedy-by-comfort-zone/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 228",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/pure-spa-by-natura-bisse/acceso-spa-masaje-relajante/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 229",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/pure-spa-by-natura-bisse/clase-de-yoga-desayuno-acceso-spa-masaje-relajante/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 230",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/pure-spa-by-natura-bisse/clase-de-yoga-acceso-spa-masaje-relajante/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 231",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/pure-spa-by-natura-bisse/acceso-spa-desayuno-masaje-relajante/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 232",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/pure-spa-by-natura-bisse/daypass-con-almuerzo-en-es-clot-pool-bar-sin-acceso-a-spa/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 233",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/pure-spa-by-natura-bisse/daypass-con-cocktail-y-aperitivo-sin-acceso-a-spa/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 234",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/pure-spa-by-natura-bisse/daypass-con-cocktail-y-aperitivo-acceso-a-spa/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 235",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/pure-spa-by-natura-bisse/acceso-spa/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 236",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/pure-spa-by-natura-bisse/acceso-spa-cena-menu-degustacion/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 237",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/pure-spa-by-natura-bisse/acceso-spa-desayuno/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 238",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/pure-spa-by-natura-bisse/daypass-con-almuerzo-en-es-clot-pool-bar-acceso-spa/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 239",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/pure-spa-by-natura-bisse/daypass-con-desayuno-acceso-spa/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 240",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-hipotels-eurotel-punta-rotja/masaje-y-spa-para-2/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 241",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-hipotels-eurotel-punta-rotja/jacuzzi-privado-spa-y-masaje-para-2/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 242",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-hipotels-eurotel-punta-rotja/experiencia-best-friends-para-2/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 243",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-hipotels-eurotel-punta-rotja/pro-radiance-facial-y-spa/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 244",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-hipotels-eurotel-punta-rotja/spa-masaje-y-almuerzo-para-2/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 245",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-hipotels-eurotel-punta-rotja/full-day-spa-y-masaje/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 246",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-hipotels-eurotel-punta-rotja/jacuzzi-privado-spa-masaje-y-almuerzo-para-2/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 247",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-hipotels-eurotel-punta-rotja/ritual-ayurvedico-y-spa/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 248",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-hipotels-eurotel-punta-rotja/active-recovery-y-spa/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 249",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-hipotels-eurotel-punta-rotja/masaje-deluxe-y-spa-para-2/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 250",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/hm-jaime-iii/circuito-spa-brunch-domingos/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 251",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/hm-jaime-iii/circuito-spa-almuerzo-menu/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 252",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/hm-jaime-iii/circuito-spa-desayuno-buffet/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 253",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/hm-jaime-iii/circuito-spa/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 254",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/oxigenoterapia-25-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 255",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/ritual-facial-c-plus-energy/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 256",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/ritual-pies-de-seda/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 257",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/ritual-facial-timexpert/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 258",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/exfoliante-personalizado/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 259",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/quiromasaje-50-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 260",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/ritual-facial-eyelift/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 261",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/ritual-facial-kobido/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 262",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/nature-aloe/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 263",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/ritual-pediluxury/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 264",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/zen-para-dos/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 265",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/masaje-piernas-cansadas/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 266",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/belleza-relax/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 267",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/spa-masaje-para-dos-20-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 268",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/ritual-manos-de-seda/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 269",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/ritual-dulce-espera/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 270",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/ritual-bambu/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 271",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/ritual-aloexperience/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 272",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/reflexologia-podal/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 273",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/masaje-anticelulitico/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 274",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/masaje-relajante-50-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 275",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/ritual-locura-praline/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 276",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/masaje-craneofacial/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 277",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/spa-masaje-para-dos-50-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 278",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/ritual-polinesia/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 279",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/quiromasaje-25-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 280",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/masaje-abhyanga/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 281",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/circuito-spa-adulto/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 282",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/relax-clean/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 283",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/ritual-facial-oxigeno/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 284",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/ritual-fangos-del-atlantico/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 285",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/masaje-relajante-25-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 286",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sedatio-cadiz/ritual-suenos-de-cereza/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 287",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/sens-spa/especial-pareja-circuito-spa-y-belleza-masaje-de-25min-servicio-de-te-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 288",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/sens-spa/especial-pareja-circuito-spa-y-belleza-servicio-de-te-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 289",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/sens-spa/especial-pareja-circuito-spa-y-belleza-masaje-15min-servicio-de-te-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 290",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/sens-spa/circuito-sensaciones/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 291",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/sens-spa/especial-pareja-circuito-spa-y-belleza-masaje-55min-servicio-de-te-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 292",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/sens-spa/ritual-al-andalus/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 293",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-de-aromaterapia-25-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 294",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-de-aromaterapia-55-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 295",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-relax-del-rostro-cuello-y-escote/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 296",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/sens-spa/circuito-spa-y-belleza/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 297",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-de-cerveza-parcial-25/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 298",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-exclusivity-25-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 299",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-exclusivity-75-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 300",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-exclusivity-55-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 301",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/masaje-sueco-30-min-doble/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 302",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/masaje-sueco-60-min-doble/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 303",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/masaje-sueco-30-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 304",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/masaje-sueco-60-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 305",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/day-pass-dia-completo/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 306",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/day-pass-5h-lunes-a-jueves/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 307",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/aromaterapia-con-velas/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 308",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/aromaterapia-con-velas-doble/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 309",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/holistic-experience-60-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 310",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/pure-salt-ritual/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 311",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/o-spa-signature-doble/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 312",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/o-spa-signature/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 313",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/cielo-y-tierra-30-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 314",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/spa-cena/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 315",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/masaje-aromaterapia-50min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 316",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/bienestar-para-dos-50min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 317",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/ritual-le-voyage-1h-15min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 318",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/spa-almuerzo/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 319",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/spa-masaje-gastronomia/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 320",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/masaje-relajante-50min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 321",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/spa-desayuno/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 322",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/spa-masaje-desayuno/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 323",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/spa-oasis/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 324",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/las-mil-y-una-noche-masaje-35min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 325",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/masaje-desayuno/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 326",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/ritual-namaste-en-pareja-1h/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 327",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/spa-ninos/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 328",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/masaje-de-espalda-cuello-hombros-25min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 329",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/masaje-prenatal-50min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 330",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-bellesa-claret/relajacion-de-son-claret-aromaterapia-60-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 331",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-bellesa-claret/ritual-corporal-de-temporada/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 332",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-bellesa-claret/facial-bellesa-de-claret/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 333",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-bellesa-claret/flor-de-almendro-oferta-para-residentes/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 334",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-bellesa-claret/relajacion-tejido-profundo-jardin-de-son-claret-exterior-60-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 335",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-bellesa-claret/relajacion-jardin-de-son-claret-exterior-90-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 336",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-bellesa-claret/bellesas-anti-estres-60-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 337",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-bellesa-claret/spa-con-masaje-y-almuerzo/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 338",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-bellesa-claret/day-pass-con-cama-balinesa-y-gastronomia-para-2/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 339",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-bellesa-claret/la-sulatane-de-saba-facial-90min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 340",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-bellesa-claret/hammam/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 341",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-bellesa-claret/masaje-con-pindas-herbales-calientes-60-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 342",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-bellesa-claret/masaje-con-piedras-calientes-y-piedras-semi-preciosas-60-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 343",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-bellesa-claret/relajacion-tejido-profundo-60-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 344",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-beer-spa-masaje-15-minutos-en-sala-comun-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 345",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-beer-spa-masaje-holistico-con-aceite-de-cerveza-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 346",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-beer-spa-masaje-completo-55min-sala-comun-1-persona/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 347",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-beer-spa-masaje-localizado-25min-sala-comun-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 348",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-beer-spa-masaje-localizado-25min-sala-comun-1-persona/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 349",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-beer-spa-masaje-completo-75min-sala-comun-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 350",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-beer-spa-masaje-completo-55min-sala-comun-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 351",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-beer-spa-masaje-15-minutos-en-sala-comun-1-persona/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 352",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-vip-en-sala-privada-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 353",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-premium-en-sala-privada-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 354",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-beer-spa-en-sala-comun/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 355",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-beer-spa-en-sala-comun-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 356",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/masaje-exclusivity-de-cerveza-25min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 357",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/masaje-exclusivity-de-cerveza-55min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 358",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience-hipotels-flamenco/circuito-spa-masaje-holistico-desayuno-buffet-para-2-personas/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 359",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience-hipotels-flamenco/circuito-spa-masaje-30-min-para-2-personas/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 360",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience-hipotels-flamenco/circuito-spa-masaje-50-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 361",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience-hipotels-flamenco/circuito-spa-masaje-30-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 362",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience-hipotels-flamenco/circuito-spa-masaje-30-min-desayuno-buffet/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 363",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience-hipotels-flamenco/circuito-spa-masaje-holistico-cena-para-2-personas/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 364",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/aqua-rituals-experience-hipotels-flamenco/circuito-spa-masaje-sensorial/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 365",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/guadalajara/spa-rural-los-anades/natur-cosmetic-de-lavanda-premium/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 366",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/guadalajara/spa-rural-los-anades/natur-cosmetic-de-chocolate/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 367",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/guadalajara/spa-rural-los-anades/natur-cosmetic-de-cafe/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 368",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/guadalajara/spa-rural-los-anades/ritual-lavanda-spa/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 369",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/guadalajara/spa-rural-los-anades/circuito-privado-de-spa-brunch/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 370",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/guadalajara/spa-rural-los-anades/ritual-en-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 371",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/guadalajara/spa-rural-los-anades/circuito-privado-de-spa/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 372",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/guadalajara/spa-rural-los-anades/massage-flor-de-sal/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 373",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/guadalajara/spa-rural-los-anades/ritual-de-belleza/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 374",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/guadalajara/spa-rural-los-anades/masaje-japones-kobido/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 375",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/guadalajara/spa-rural-los-anades/masaje-sensitivo/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 376",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/guadalajara/spa-rural-los-anades/circuito-privado-de-spa-tratamiento-natur-cosmetic-a-elegir/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 377",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/guadalajara/spa-rural-los-anades/circuito-privado-de-spa-ritual-en-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 378",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/guadalajara/spa-rural-los-anades/circuito-privado-de-spa-terapia-a-elegir/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 379",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/guadalajara/spa-rural-los-anades/masaje-relajante/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 380",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-es-port/drenaje-linfatico-metodo-vodderr/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 381",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-es-port/mens-facial/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 382",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-es-port/spa/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 383",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-es-port/momento-spa-masaje/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 384",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-es-port/masaje-terapeutico/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 385",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-es-port/masaje-beauty-back/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 386",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-es-port/tonic-c/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 387",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-es-port/mediterraneo/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 388",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-es-port/ayurveda-pinda-swedana/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 389",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-es-port/soller-sensaciones/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 390",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-es-port/masaje-antiestres/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 391",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-es-port/masaje-piedras-calientes/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 392",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-es-port/masaje-mediterranean-champi/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 393",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-es-port/ayurveda-abhyanga/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 394",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/ritual-chocoterapia/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 395",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/piscina-de-tratamientos-servicio-de-te-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 396",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/especial-pareja-piscina-de-tratamientos-masaje-de-15-min-servicio-de-te-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 397",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/especial-pareja-piscina-de-tratamientos-masaje-de-25-min-servicio-de-te-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 398",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/especial-pareja-piscina-de-tratamientos-masaje-de-55-min-servicio-de-te-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 399",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/masaje-de-aromaterapia-55-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 400",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/masaje-de-aromaterapia-25-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 401",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/limpieza-facial-profunda/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 402",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/piscina-interior-con-chorros-lumbares-y-cervicales/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 403",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/masaje-esencia-naturales-25-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 404",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/masaje-esencias-naturales-55-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 405",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/masaje-exclusivity-75-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 406",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/masaje-exclusivity-25-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 407",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/masaje-exclusivity-55-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 408",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/circuito-beer-spa-masaje-completo-55-min-sala-comun-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 409",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/circuito-beer-spa-masaje-localizado-25min-sala-comun-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 410",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/circuito-beer-spa-masaje-15-minutos-sala-comun-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 411",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/circuito-beer-spa-masaje-localizado-25min-sala-comun-1-persona/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 412",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/circuito-beer-spa-en-sala-comun/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 413",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/circuito-beer-spa-masaje-15-minutos-sala-comun-1-persona/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 414",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/circuito-beer-spa-estudiantes/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 415",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/circuito-beer-spa-en-sala-comun-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 416",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/masaje-exclusivity-de-cerveza-25min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 417",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/masaje-exclusivity-de-cerveza-25min-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 418",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/circuito-supreme-en-sala-privada-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 419",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/masaje-exclusivity-de-cerveza-55min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 420",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/circuito-premium-en-sala-privada-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 421",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/circuito-vip-en-sala-privada-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 422",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/jacuzzi-privado-spa-masaje-y-almuerzo-para-2/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 423",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/jacuzzi-privado-spa-y-masaje-para-2/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 424",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/experiencia-best-friends-para-2/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 425",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/pro-radiance-facial-y-spa/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 426",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/full-day-spa-y-masaje/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 427",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/active-recovery-y-spa/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 428",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/masaje-deluxe-y-spa-para-2/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 429",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/spa-masaje-y-almuerzo-para-2/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 430",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/ritual-ayurvedico-y-spa/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 431",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/masaje-y-spa-para-2/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 432",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/noches-privadas-spa-1hora-masaje-30-para-dos/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 433",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/ritual-taoista-real/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 434",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/tratamiento-taoista/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 435",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/gran-ritual-de-polinesia-aloha/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 436",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/gran-ritual-de-magreb-90-minutos/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 437",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/celestial-day/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 438",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/ritual-iluminador-flores-de-bali-50/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 439",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/mallorca-relajese-y-disfrute/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 440",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/relajese-disfrute-de-verano/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 441",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/masaje-balines-a-4-manos/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 442",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/masaje-dulce-espera/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 443",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/masaje-relajante-oriental-80/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 444",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/acceso-spa-no-residente/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 445",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/acceso-spa-residente/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 446",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/felicidad-a-duo/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 447",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/relajese-disfrute-premium/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 448",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/ritual-euforia-doble/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 449",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/noches-privadas-spa-1hs/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 450",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/cozzy-uso-privado-por-5-horas/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 451",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/ritual-energizante-ayurveda-con-welcome-touch-para-dos-50-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 452",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/mar-y-cielo/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 453",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/foot-reflexology-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 454",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/deep-tissue-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 455",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/oriental/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 456",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/antiestres-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 457",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/ayurveda-royal-thai-poultice/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 458",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/oriental-1/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 459",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/escapada-relax-en-pareja-cena-spa-y-masaje/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 460",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/senses-night/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 461",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/circuito-hydrowellness/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 462",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/mananas-deluxe/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 463",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/foot-reflexology/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 464",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/deep-tissue-40/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 465",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/antiestres/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 466",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-tarifa/ritual-de-chocoterapia/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 467",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-tarifa/especial-pareja-piscina-de-tratamientos-masaje-de-25-min-servicio-de-te-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 468",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-tarifa/especial-pareja-piscina-de-tratamientos-masaje-de-15-min-servicio-de-te-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 469",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-tarifa/especial-pareja-piscina-de-tratamientos-masaje-de-55-min-servicio-de-te-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 470",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-tarifa/especial-pareja-piscina-de-tratamientos-pareja/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 471",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-tarifa/depilacion-piernas-completas/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 472",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-tarifa/masaje-exclusivity-75-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 473",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-tarifa/depilacion-de-torax/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 474",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-tarifa/masaje-de-aromaterapia-25-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 475",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-tarifa/masaje-de-aromaterapia-55-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 476",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-tarifa/cura-relax-antiestres-desfatigante-2-dias/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 477",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-tarifa/peeling-corporal-de-coco-hidratacion-completa/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 478",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-tarifa/piscina-de-tratamientos/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 479",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-tarifa/piscina-interior-ninos/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 480",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-tarifa/masaje-exclusivity-25-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 481",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-tarifa/masaje-holistico-60-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 482",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/spa-tarifa/masaje-exclusivity-55-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 483",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hammam-vejer/masaje-de-piedras-calientes/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 484",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hammam-vejer/exclusividad-circuito/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 485",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hammam-vejer/ritual-marrakech/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 486",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hammam-vejer/masaje-kenitra/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 487",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hammam-vejer/masaje-asilah/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 488",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hammam-vejer/masaje-essaouira/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 489",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hammam-vejer/ritual-clasico-bono-5-sesiones/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 490",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hammam-vejer/ritual-clasico-bono-10-sesiones/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 491",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hammam-vejer/masaje-facial-de-cuello-cabeza-y-cara-mascarilla-facial/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 492",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hammam-vejer/ritual-clasico-bono-20-sesiones/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 493",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hammam-vejer/circuito-clasico/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 494",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/hammam-vejer/exfoliacion-en-seco/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 495",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/aumallia-hotel-spa/day-pass-masaje-60/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 496",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/aumallia-hotel-spa/masaje-relajante/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 497",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/aumallia-hotel-spa/day-pass-almuerzo/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 498",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/aumallia-hotel-spa/day-pass/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 499",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/mallorca/aumallia-hotel-spa/masaje-deportivo/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 500",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/masaje-personalizado-20-min-1/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 501",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/corporal-vitalidad-e-hidratacion/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 502",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/ritual-esencia-de-nammu/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 503",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/faciales-personalizados/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 504",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/masaje-personalizado-50-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 505",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/masaje-personalizado-20-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 506",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/aromaterapia-con-vela/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 507",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/ritual-capricho-de-la-naturaleza/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 508",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/circuito-spa/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 509",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/masaje-especial-pre-mama/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 510",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/ritual-anti-estres/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 511",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/corporal-hidroterapia-e-hidratacion/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 512",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/masaje-celta-de-piedras-calientes-y-frias-20-min/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 513",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/corporal-marino-remineralizante/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 514",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/masaje-para-ninos/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.name ?? "Service landing: 515",
    path: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/experiencia-espalda-en-equilibrio/",
    props: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.props ?? false,
    meta: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta || {},
    alias: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.alias || [],
    redirect: ServiceDetail_46page7j0L5XapreN3Pw9jz390adDf7vK2OHelmtqCegzQZqYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-83715bb7-74af-4273-85ae-6d0a16abda57/b2c-build/core/pages/service/ServiceDetail.page.vue")
  }
]